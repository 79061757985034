import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { adminService } from "../services/admin.service"
import { Error } from "../pages/error"
import { formatDateHour } from "../services/utils"
import { Loader } from "./loader"
import { setUpperPopup,setPopup } from "../store/actions/general.actions"

export function ControlCurrent() {
    const dispatch = useDispatch()
    const [approvedShows, setapprovedShows] = useState([])
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        loadapprovedShows()
    }, [])

    const loadapprovedShows = async () => {
        try {
            setLoader(true)
            const pendings = await adminService.getShows('approved')
            setapprovedShows(pendings)
            setLoader(false)
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const cancelShow = async (id) => {
        try {
            await adminService.cancelShow(id)
            setapprovedShows(approvedShows.filter(show => show._id !== id))
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    if (error) return <Error />

    if (loader) return <Loader />

    try {
        return <div className="control-current control-history">
            <p className="list-count">Upcoming shows : <span>{approvedShows.length}</span></p>
            {approvedShows.length > 0 && <table >
                <thead>
                    <tr>
                        <td>Creator</td>
                        <td>Title</td>
                        <td>Date</td>
                        <td>Price</td>
                        <td>Thumbnail</td>
                        <td>Actions</td>
                    </tr>
                </thead>
                <tbody style={{ zIndex: '1001' }}>
                    {approvedShows.map((show, idx) => <tr key={idx}>
                        <td>{show.miniCreator.nickName + `(${show.miniCreator.phone})`}</td>
                        <td>{show.title}</td>
                        <td>{formatDateHour(show.date)}</td>
                        <td>{show.price}₪</td>
                        <td className="thumbnail"><img src={show.img} onClick={() => dispatch(setPopup(show.img))} /></td>
                        <td className="actions">
                            <button className="reject" onClick={() => cancelShow(show._id)}>Cancel</button>
                        </td>
                    </tr>)}
                </tbody>
            </table>}
        </div>
    }
    catch (err) {
        return <Error />
    }
}
import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setPopup, setUpperPopup } from '../store/actions/general.actions'
import { creatorService } from '../services/creator.service'

export function PopupSupport() {
    const dispatch = useDispatch()
    const subject = useRef()
    const description = useRef()
    const email = useRef()
    const name = useRef()

    const sendMail = async (e) => {
        try {
            e.preventDefault()
            await creatorService.sendEmail({ subject: subject.current.value, description: description.current.value, email: email.current.value, name: name.current.value })
            dispatch(setPopup(''))
            dispatch(setUpperPopup('email-sent'))
        }
        catch (err) {
            console.log(err)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    return <form className="popup-support" onSubmit={sendMail}>
        <h1>how can we help?</h1>
        <p>Please describe the issue in detail. we shall contact you in the next 4 buisness days</p>
        <input placeholder="Full name" ref={name} required maxLength={30}/>
        <input placeholder="Email" ref={email} required type='email'maxLength={50}/>
        <input placeholder="What is the problem about?" maxLength={100} ref={subject} required  />
        <textarea placeholder="Describe the problem" ref={description} required maxLength={400}/>
        <button>Send</button>
    </form>
}